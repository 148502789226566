'use client';

import { useState, useEffect } from 'react';
import { useRouter, usePathname, useSearchParams } from 'next/navigation';
import styles from './extracted-facets.module.scss';
import SortSelect from './sort-select';
import BookTypeSelection from './book-type-selection';
import BookConditionSelection from './book-condition-selection';
import PriceSelection from './price-selection';
import BookCategories from './book-categories';
import BookTags from './book-tags';
import Button from '~/components/button';
import { Flex } from '~/components/layout/flex';
import {
  SORT_SELECT_OPTIONS,
  parseAppliedFilters,
  allDefaultFacets,
  clearFacetValue,
  parseSortBy,
  CONDITIONS,
  isFacetFilterSelected,
} from 'sdk';
import Pill from '~/components/pill';
import CloseSVG from '~/assets/svg/icons/close';
import COLORS from '~/lib/helpers/color-helper';

interface ExtractedFacetsProps {
  formatCounts: { value: string; count: number }[];
  conditionCounts: { value: string; count: number }[];
  booksResult: any;
  totalBooks: number;
}

export default function ExtractedFacets({
  formatCounts,
  conditionCounts,
  booksResult,
  totalBooks,
}: ExtractedFacetsProps) {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [isMobile, setIsMobile] = useState(false);

  const searchOptions = {
    index: 'books',
    params: {
      filter_by: searchParams.get('filter') || '',
    },
  };
  // Parse filters from filter_by parameter
  const filtersFromFilterBy = parseAppliedFilters(
    searchOptions?.params?.filter_by
  );

  // Format price filters for display
  const formattedFilters = filtersFromFilterBy.map((filter) => {
    // Handle amount filter formatting
    if (filter.field === 'amount') {
      // Check if it's a range filter
      const rangeMatch = filter.value.match(/^\[(\d+)\.\.(\d+)\]$/);
      if (rangeMatch) {
        const minCents = parseInt(rangeMatch[1]);
        const maxCents = parseInt(rangeMatch[2]);
        // Convert from cents to dollars and format
        const minDollars = minCents / 100;
        const maxDollars = maxCents / 100;
        // Format to whole numbers if no decimals
        const formatPrice = (price: number) =>
          Number.isInteger(price) ? price.toString() : price.toFixed(2);

        return {
          field: filter.field,
          value: `$${formatPrice(minDollars)} - $${formatPrice(maxDollars)}`,
        };
      }

      // Check for greater than or equal to filter
      const gteMatch = filter.value.match(/^>=(\d+)$/);
      if (gteMatch) {
        const cents = parseInt(gteMatch[1]);
        const dollars = cents / 100;
        const formatPrice = (price: number) =>
          Number.isInteger(price) ? price.toString() : price.toFixed(2);

        return {
          field: filter.field,
          value: `$${formatPrice(dollars)}+`,
        };
      }

      // Check for less than or equal to filter
      const lteMatch = filter.value.match(/^<=(\d+)$/);
      if (lteMatch) {
        const cents = parseInt(lteMatch[1]);
        const dollars = cents / 100;
        const formatPrice = (price: number) =>
          Number.isInteger(price) ? price.toString() : price.toFixed(2);

        return {
          field: filter.field,
          value: `Up to $${formatPrice(dollars)}`,
        };
      }
    }

    // Return original for non-amount filters
    return filter;
  });

  // Add format filter if present
  const formatFilter = searchParams.get('format');
  const appliedFiltersArray = [...formattedFilters];

  if (formatFilter) {
    appliedFiltersArray.push({
      field: 'format',
      value: formatFilter,
    });
  }

  // Check for condition filters in the filter string
  const filterString = searchParams.get('filter') || '';

  // Add condition filters to applied filters
  for (const condition of CONDITIONS) {
    if (filterString.includes(`condition:=${condition}`)) {
      // If condition is found in filter string but not already in appliedFiltersArray
      const exists = appliedFiltersArray.some(
        (filter) => filter.field === 'condition' && filter.value === condition
      );

      if (!exists) {
        appliedFiltersArray.push({
          field: 'condition',
          value: condition,
        });
      }
    }
  }

  // Look for any genre.display filters in the filter string
  if (booksResult?.facet_counts) {
    const genreFacets =
      booksResult.facet_counts.find(
        (facet) => facet.field_name === 'genre.display'
      )?.counts || [];

    // Check each genre against the filter string
    genreFacets.forEach((genre) => {
      if (isFacetFilterSelected(filterString, 'genre.display', genre.value)) {
        // If genre is found in filter string but not already in appliedFiltersArray
        const exists = appliedFiltersArray.some(
          (filter) =>
            filter.field === 'genre.display' && filter.value === genre.value
        );

        if (!exists) {
          appliedFiltersArray.push({
            field: 'genre.display',
            value: genre.value,
          });
        }
      }
    });

    // Look for any hashtag filters in the filter string
    const hashtagFacets =
      booksResult.facet_counts.find((facet) => facet.field_name === 'hashtags')
        ?.counts || [];

    // Check each hashtag against the filter string
    hashtagFacets.forEach((hashtag) => {
      if (isFacetFilterSelected(filterString, 'hashtags', hashtag.value)) {
        // If hashtag is found in filter string but not already in appliedFiltersArray
        const exists = appliedFiltersArray.some(
          (filter) =>
            filter.field === 'hashtags' && filter.value === hashtag.value
        );

        if (!exists) {
          appliedFiltersArray.push({
            field: 'hashtags',
            value: hashtag.value,
          });
        }
      }
    });
  }

  // Sort filter is not added to applied filters as it's displayed separately

  function doesFacetHaveFilters(appliedFiltersArray, facetKey: string) {
    return appliedFiltersArray.some((filter) => filter.field === facetKey);
  }

  function handleClearAllFilters(option: string[]) {
    const params = new URLSearchParams(searchParams);

    // Clear filter parameter which contains condition filters
    params.delete('filter');

    // Also clear format parameter if it exists
    params.delete('format');

    // Note: We don't clear sort parameter anymore as it's not part of the applied filters
    // params.delete('sort');

    // Reset to page 1
    params.set('page', '1');

    router.push(`${pathname}?${params.toString()}`, { scroll: false });
  }

  function handlePillClick(field: string, value: string) {
    const params = new URLSearchParams(searchParams);

    // Handle format parameter separately
    if (field === 'format') {
      params.delete('format');
    } else if (field === 'sort') {
      // Handle sort parameter
      params.delete('sort');
    } else if (field === 'condition') {
      // Handle condition filters
      const filterString = params.get('filter') || '';

      // Remove the condition from filter string by building new condition filter
      const selectedConditions = CONDITIONS.filter(
        (c) => filterString.includes(`condition:=${c}`) && c !== value
      );

      // Remove existing condition filter group
      let newFilterString = filterString.replace(
        /\(condition:=[^)]+\)(\s*&&\s*)?/g,
        ''
      );
      newFilterString = newFilterString.replace(/^&&\s*|\s*&&\s*$/g, '').trim();

      // Add back the other conditions if there are any
      if (selectedConditions.length > 0) {
        const conditionFilter = `(${selectedConditions
          .map((c) => `condition:=${c}`)
          .join(' || ')})`;

        if (newFilterString) {
          newFilterString = `${newFilterString} && ${conditionFilter}`;
        } else {
          newFilterString = conditionFilter;
        }
      }

      // Update or remove the filter parameter
      if (newFilterString.trim()) {
        params.set('filter', newFilterString.trim());
      } else {
        params.delete('filter');
      }
    } else if (field === 'genre.display' || field === 'hashtags') {
      // Handle genre.display and hashtag filters by toggling them
      const filterString = searchParams.get('filter') || '';

      // Create the filter expression
      const filterExpression = `${field}:${value}`;

      // Remove the filter from the filter string
      const regex = new RegExp(
        `(${filterExpression})(\\s*&&\\s*)?|\\s*&&\\s*(${filterExpression})`
      );
      let newFilterString = filterString.replace(regex, '').trim();

      // Update or remove the filter parameter
      if (newFilterString.trim()) {
        params.set('filter', newFilterString.trim());
      } else {
        params.delete('filter');
      }
    } else {
      // Handle other filter-based parameters
      const currentFilter = searchParams.get('filter') || '';
      const newFilter = clearFacetValue(currentFilter, [`${field}:${value}`]);

      if (newFilter) {
        params.set('filter', newFilter);
      } else {
        params.delete('filter');
      }
    }

    params.set('page', '1');
    router.push(`${pathname}?${params.toString()}`, { scroll: false });
  }

  useEffect(() => {
    const handleResize = () => {
      const IS_MOBILE = window.innerWidth < 768;
      setIsMobile(IS_MOBILE);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Flex
        id="container"
        direction="row"
        justify="space-between"
        className={styles['main-container']}
      >
        <div className={styles['filters-wrapper']}>
          <div className={styles['filters-section']}>
            <div className={styles['filter-container']}>
              <div className={styles['filter-row']}>
                <div className={styles['buttons-container']}>
                  <div className={styles['parent-container']}>
                    <BookTypeSelection formatCounts={formatCounts} />
                  </div>

                  <div className={styles['parent-container']}>
                    <BookConditionSelection conditionCounts={conditionCounts} />
                  </div>

                  <div className={styles['parent-container']}>
                    <BookCategories
                      facetCounts={booksResult}
                      searchOptions={searchOptions as any}
                      handleOnClick={handlePillClick}
                    />
                  </div>

                  <div className={styles['parent-container']}>
                    <PriceSelection
                      searchResults={booksResult}
                      searchOptions={searchOptions as any}
                    />
                  </div>

                  <div className={styles['parent-container']}>
                    <BookTags
                      facetCounts={booksResult}
                      searchOptions={searchOptions as any}
                      handleOnClick={handlePillClick}
                    />
                  </div>
                </div>
                <div className={styles['sort-section-mobile']}>
                  <div className={styles['parent-container']}>
                    <SortSelect />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles['applied-filters-container']}>
              {appliedFiltersArray.length > 0 && (
                <>
                  {appliedFiltersArray.map((filter, key) => (
                    <div key={key}>
                      <Pill
                        key={key}
                        text={
                          filter.field === 'hashtags'
                            ? `#${filter.value.replace(/^#/, '')}`
                            : filter.value
                        }
                        className={styles['pill']}
                        icon={
                          <div className={styles['pill-icon']}>
                            <CloseSVG strokeColor={COLORS.copy} />
                          </div>
                        }
                        iconPosition="right"
                        outline={true}
                        onClick={() => {
                          handlePillClick(filter.field, filter.value);
                        }}
                      />
                    </div>
                  ))}

                  <Button
                    text="Clear all"
                    style="clear"
                    onPress={() => {
                      handleClearAllFilters(allDefaultFacets);
                    }}
                    className={styles['clear-all-button']}
                  />
                </>
              )}
            </div>

            <div className={styles['books-found']}>
              <strong>{totalBooks} books found</strong>
            </div>
          </div>
        </div>

        <div className={styles['sort-section-desktop']}>
          <div className={styles['parent-container']}>
            <SortSelect />
          </div>
        </div>
      </Flex>
    </>
  );
}
